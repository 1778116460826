import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SubscriptionPriceModel } from '../_models/admin/Subscrption.Model';
import { responseModel } from '../_models/response.model';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  headers: { headers: any; };
  constructor (private _http: HttpClient) {
    // translate.get(this.resetFilter).subscribe(data => {
    //   this.resetFilter = data
    // })
  }
  public resetFilter = 'Reset filters';
  public exportPDF = 'Export PDF';
  public exportCSV = 'Export CSV';

  // For Sending headers to API
  getHeaders() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return (this.headers = httpOptions);
  }
  // function to upsert the role into the database
  fnRoleUpsert(values) {
    return this._http.post(environment.apiUrl + 'upsert-role', values);
  }

  fnRoleDelete(values) {
    return this._http.post(environment.apiUrl + 'delete-role', values);
  }
  // function to upsert the user into the database
  fnUserUpsert(values) {
    return this._http.post(environment.apiUrl + 'upsert-user', values);
  }
  // function to delete the user into the database
  fnUserdelete(values) {
    return this._http.post(environment.apiUrl + 'delete-user', values);
  }
  // function to insert and update Lookups
  fnLookupUpsert(values) {
    return this._http.post(environment.apiUrl + 'upsert-Lookups', values);
  }
  fnLookupDelete(values) {
    return this._http.post(environment.apiUrl + 'delete-Lookup', values);
  }
  fnGetCodeNames() {
    return this._http.post(environment.apiUrl + 'get-CodeMasterNames', null);
  }
  fnGetLookupsList(values) {
    return this._http.get(
      environment.apiUrl + 'get-LookupsList?OwnerID=' + values
    );
  }
  // set owner info details
  fnUpsertOwnerInfo(val) {
    return this._http.post(environment.apiUrl + 'upsert-owner-info', val);
  }
  // Get owner info details
  fnGetOwnerInfo(val) {
    return this._http.post<responseModel>(
      environment.apiUrl + 'get-owner-info',
      val
    );
  }
  // function to upsert the Branch into the database
  fnUpsertBranch(values) {
    return this._http.post(environment.apiUrl + 'upsert-branch', values);
  }
  // function to delete the Branch into the database
  fnDeleteBranch(values) {
    return this._http.post(environment.apiUrl + 'delete-branch', values);
  }
  //
  fnGetBranchDetails(val) {
    return this._http.post<responseModel>(
      environment.apiUrl + 'get-branch-details',
      val
    );
  }
  //#region
  // add and edit denomination
  fnUpsertDenomination(values) {
    return this._http.post(environment.apiUrl + 'upsert-denomination', values);
  }
  // delete denomination
  fnDeleteDenomination(values) {
    return this._http.post(environment.apiUrl + 'delete-denomination', values);
  }
  //#endregion

  //Get Owner Profile Info
  fnGetOwnerLogo() {
    return this._http.get(environment.apiUrl + 'get-owner-logo');
  }

  //#region Agents
  fnAgentDelete(val) {
    return this._http.post<responseModel>(
      `${ environment.apiUrl }delete-agent`,
      val
    );
  }
  fnAgentUpsert(val) {
    return this._http.post<responseModel>(
      `${ environment.apiUrl }upsert-agent`,
      val
    );
  }
  fnAgentGetData(val) {
    return this._http.post<responseModel>(
      `${ environment.apiUrl }get-agent-data`,
      val
    );
  }
  //#endregion

  //#region Preferences

  fnEditServerPreferences(val) {
    return this._http.post<responseModel>(
      `${ environment.apiUrl }edit-server-preferences`,
      val
    );
  }

  //#endregion

  fnCreatePayment() {
    return this._http.post(`${ environment.apiUrl }create-payment`, null);
  }

  fnGetSubscriptionProductPrices() {
    return this._http.post<SubscriptionPriceModel>(
      `${ environment.apiUrl }get-subscription-price-list`,
      null
    );
  }

  fnEcexuteSubscrptionOrder(a) {
    return this._http.post(
      `${ environment.apiUrl }execute-subscription-order`,
      a
    );
  }

  fnFetchAllPermissions(a) {
    return this._http.post(`${ environment.apiUrl }fetch-all-permissions`, a);
  }
  fnAddModuleToExceptional(a) {
    return this._http.post(`${ environment.apiUrl }add-module-to-exceptional`, a);
  }
  fnUpsertPermissions(a) {
    return this._http.post(`${ environment.apiUrl }upsert-permissions`, a);
  }

  //#region Currency Update timings
  fnCurrencyUpdateTimeDelete(val) {
    return this._http.post<responseModel>(
      `${ environment.apiUrl }delete-currency-rate-updated-time`,
      val
    );
  }
  fnCurrencyUpdateTimeUpsert(val) {
    return this._http.post<responseModel>(
      `${ environment.apiUrl }upsert-currency-rate-updated-time`,
      val
    );
  }
  fnGetCurrencyUpdateTimings() {
    return this._http.get<responseModel>(
      `${ environment.apiUrl }get-currency-rate-updated-timings`
    );
  }
  //#endregion

  fnGeneratePdf(val) {
    return this._http.post<responseModel>(
      `${ environment.apiUrl }generate-pdf`,
      val
    );
  }

  // update user preferred language
  fnUpdateUSerPreferredLagulage(val) {
    return this._http.post<responseModel>(
      `${ environment.apiUrl }update-preferred-language`,
      val
    );
  }

  fnCheckTillLogin(val) {
    return this._http.get<responseModel>(
      environment.apiUrl + 'till-login-check?OwnerID=' + val
    );
  }

  fnKeyValueDelete(values) {
    return this._http.post(environment.apiUrl + 'delete-key-value', values);
  }

  fnkeyValueUpsert(values) {
    return this._http.post(environment.apiUrl + 'upsert-key-value', values);
  }

  fnGetValue(val) {
    return this._http.post<responseModel>(
      environment.apiUrl + 'get-key-value',
      val
    );
  }
  fnGetKeyValues() {
    return this._http.get<responseModel>(environment.apiUrl + 'get-key-values');
  }

  fnSaveCurrencyBoardSettings(val) {
    return this._http.post<responseModel>(
      environment.apiUrl + 'save-currency-rate-board-settings',
      val
    );
  }
  fnGetCurrencyBoardSettings() {
    return this._http.get<responseModel>(
      environment.apiUrl + 'get-currency-rate-board-settings'
    );
  }

  fnGetServerPreverences() {
    return this._http.get<responseModel>(
      environment.apiUrl + 'get-server-preferences'
    );
  }

  //#region Date Range

  //For deleting the Date Range
  fnDateRangeDelete(values) {
    return this._http.post(environment.apiUrl + 'delete-daterange', values);
  }

  //For Adding and Editing the Date Range
  fnDateRangeUpsert(values) {
    return this._http.post(environment.apiUrl + 'upsert-daterange', values);
  }

  //#endregion

  //#region Merge Accounts
  fnGetAccountsDD() {
    return this._http.get<responseModel>(environment.apiUrl + 'get-account-dd');
  }
  fnMergeAccounts(values) {
    return this._http.post(environment.apiUrl + 'merge-accounts', values);
  }
  //#endregion

  //#region Owner Profile Info
  //Get Owner Profile Info
  fnGetOwnerProfileInfo(val) {
    return this._http.post<responseModel>(
      environment.apiUrl + 'get-owner-profile-info',
      val
    );
  }

  //Upsert owner Profile Info
  fnUpsertOwnerProfileInfo(val) {
    return this._http.post(
      environment.apiUrl + 'upsert-owner-profile-info',
      val
    );
  }
  //#endregion
  //#region Secret Key

  //For deleting the secret key
  fnSecretKeyDelete(values) {
    return this._http.post(environment.apiUrl + 'delete-secretkey', values);
  }

  //For Adding and Editing the secret key
  fnSecretKeyUpsert(values) {
    return this._http.post(environment.apiUrl + 'upsert-secretkey', values);
  }

  fnSaveFeeRule(values) {
    return this._http.post<responseModel>(
      environment.apiUrl + 'upsert-fee-rule',
      values
    );
  }

  fnDeleteFeeRule(values) {
    return this._http.post<responseModel>(
      environment.apiUrl + 'delete-fee-rule',
      values
    );
  }

  fnGetFeeRule(val) {
    let params = new HttpParams();
    params = params.set('ruleId', val);
    return this._http.get<responseModel>(environment.apiUrl + 'get-fee-rule', {
      params: params,
    });
  }

  fnUpdate2FASettings() {
    return this._http.post<responseModel>(
      `${ environment.apiUrl }update-user-2fa`,
      null
    );
  }
  fnGetClerkList(val) {
    let params = new HttpParams();
    params = params.set('branchId', val);
    return this._http.get<responseModel>(
      environment.apiUrl + 'get-clerk-list',
      { params: params }
    );
  }

  fnUpsertClerk(values) {
    return this._http.post<responseModel>(
      environment.apiUrl + 'upsert-clerk',
      values
    );
  }

  fnDeleteClerk(values) {
    return this._http.post<responseModel>(
      environment.apiUrl + 'delete-clerk',
      values
    );
  }

  fnGetAccountBalances(values) {
    return this._http.post<responseModel>(
      environment.apiUrl + 'get-account-balances',
      values
    );
  }

  fnSaveAccountAdjustments(values, AccountID, date: string, sessionId: any) {
    let params = new HttpParams();
    params = params.set('accountId', AccountID);
    params = params.set('adjustmentDate', date);
    params = params.set('sessionId', sessionId);

    return this._http.post<responseModel>(
      environment.apiUrl + 'save-account-adjustments',
      values,
      { params: params }
    );
  }

  fnGetAllClerks() {
    return this._http.get<responseModel>(environment.apiUrl + 'get-all-clerks');
  }

  fnGetPowerBIUrl() {
    return this._http.get<responseModel>(
      environment.apiUrl + 'get-power-bi-url'
    );
  }
}
