import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { responseModel } from "../_models/response.model";

@Injectable({
    providedIn: "root"
})
export class UserSessionService {
    headers: { headers: any; };
    constructor (private _http: HttpClient) { }

    getHeaders() {
        const httpOptions = {
            headers: new HttpHeaders({
                "Content-Type": "application/json"
            })
        };
        return (this.headers = httpOptions);
    }

    fnCreateSession(data) {
        return this._http.post<responseModel>(`${ environment.apiUrl }CreateSession`, data);
    }

    fnGetUserActiveSession() {
        return this._http.get<responseModel>(`${ environment.apiUrl }GetUserActiveSession`);
    }

    fnEndSession(data) {
        return this._http.post<responseModel>(`${ environment.apiUrl }EndSession`, data);
    }

    fnGetUserSessionsByUser(userId, branchId) {
        var params = new HttpParams();
        params = params.set('userId', userId);
        params = params.set('branchId', branchId);

        return this._http.get<responseModel>(`${ environment.apiUrl }GetUserSessionsByUser`, { params: params });
    }

    fnGetUserSessionSummary(data) {
        var params = new HttpParams();
        params = params.set('sessionId', data);
        return this._http.get<responseModel>(`${ environment.apiUrl }UserSessionSummary`, { params: params });
    }
}
