<h4 mat-dialog-title>

    <span align="left" *ngIf="!(f.ID.value > 0)">
        {{ 'Add Session' | translate }} &nbsp;
    </span>
    <button type="button" class="close pull-right" (click)="close()" aria-label="Close">
        <span aria-hidden="true" class="text-black">&times;</span>
    </button>
</h4>

<div mat-dialog-content>
    <div class="col-xs-12 padding-top">
        <form [formGroup]="AddUserSessionForm" (ngSubmit)="onSubmit()">
            <div class="row">
                <div class="col-xs-12 col-md-6">
                    <div class="form-group-height form-group">
                        <label class="mandatory">{{ 'Pin' | translate }}:</label>
                        <input type="text" class="form-control" placeholder="{{ 'Pin' | translate }}"
                            formControlName="Pin" />
                        <div *ngIf="submitted && f.Pin.errors" class="invalid-feedback">
                            <div *ngIf="f.Pin.errors.required">
                                {{ 'Required' | translate }}
                            </div>
                            <div *ngIf="!f.Pin.hasError('required') && f.Pin.hasError('cannotContainSpace')">
                                {{ 'Cannot contain white space' | translate }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12 col-md-6">
                    <div class="form-group-height form-group">
                        <label class="mandatory">{{ 'Start Date' | translate }}:</label>
                        <input type="text" class="form-control" formControlName="StartDate" readonly />
                        <div *ngIf="submitted && f.StartDate.errors" class="invalid-feedback">
                            <div *ngIf="f.StartDate.errors.required">
                                {{ 'Required' | translate }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row m-t-20">
                <div class="col-xs-12">
                    <button type="submit" color="primary" mat-raised-button class="m-b-10 pull-right btn-success">
                        <i class="fa fa-save m-r-5"></i>{{ 'Save' | translate }}
                    </button>
                    <button mat-raised-button type="button" (click)="close()" class="pull-right m-r-5 btn-secondary">
                        {{ 'Cancel' | translate }}
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>
