import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { SubSink } from "SubSink";
import { DecimalValidation, NullNumberToNumber } from 'src/app/_helpers/decimal.validation';
import { Localisation } from 'src/app/_services/localisation';
import { ConfirmationDialogComponent } from 'src/app/shared-dialog/confirmation-dialog/confirmation-dialog.component';
import { DenominationCalculatorComponent } from 'src/app/shared-dialog/denomination-calculator/denomination-calculator.component';
import { NotificationService } from 'src/app/shared/_services/notification.service';
import { ChangeBranchDialogComponent } from '../../_dialogs/change-branch-dialog/change-branch-dialog.component';
import { GetUserObject } from '../../_helpers/get-user-object';
import { AccountsService } from '../../_services/accounts.service';
import { InvoiceCaluclatorService } from '../../_services/invoice-caluclator.service';
import { UserSessionHandleService } from '../../_services/user-session-handle';
import { UserSessionService } from '../../_services/user-session.service';

export class CashTransferBetweenTillsModel {
  Currency: any;
  Balance: any;
  Amount: any;
  isSelectedFullAmount: any;
  AccountName: any;
}
@Component({
  selector: 'cxr-transfer-between-tills-dialog',
  templateUrl: './transfer-between-tills-dialog.component.html',
  styleUrls: ['./transfer-between-tills-dialog.component.scss']
})
export class TransferBetweenTillsDialogComponent implements OnInit {
  submitted: boolean;
  subs = new SubSink();

  constructor (
    private _fb: UntypedFormBuilder,
    private _dialogRef: MatDialogRef<TransferBetweenTillsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private _accountsService: AccountsService,
    private _notificationService: NotificationService,
    private _dialog: MatDialog,
    private translate: TranslateService,
    private _invoiceCalculatorService: InvoiceCaluclatorService,
    private _userSessionService: UserSessionService,
    private _userSessionHandleService: UserSessionHandleService
  ) {
    translate.addLangs(Localisation.supportLanguages);
    translate.setDefaultLang('en');
    const browserLang = GetUserObject.getLanguage();
    translate.use(browserLang.match(Localisation.matchLanguageExpression) ? browserLang : Localisation.defaultLanguage);
    _dialogRef.disableClose = true;
  }

  sessionId: number;
  fromTills: any = [];
  toTills: any = [];
  tillCashAccounts: CashTransferBetweenTillsModel[] = [];
  amount = [];
  disableFromTillDropDown: boolean = false;

  async ngOnInit() {
    this.formInit();
    this.getTills();
    if (GetUserObject.GetUserObject().isSessionEnabled) {
      this.sessionId = await this._userSessionHandleService.handleSession(true);
      console.log(this.sessionId);

    }
  }

  getTills() {
    this._accountsService.fnGetTillList(GetUserObject.GetUserObject()).subscribe(data => {
      if (data.Success) {
        this.fromTills = data.Data;
        if (GetUserObject.isAdmin() == false) {
          this._invoiceCalculatorService.fnCheckTillLogin(GetUserObject.GetOwnerID()).subscribe(res => {
            this.disableFromTillDropDown = true;
            this.f.FromTill.setValue(res.Data.TillID);
            this.onSelectFromTill(res.Data.TillID);
          });
        }
      }
    });
  }

  getTillsCashAccounts(val) {
    let till = this.fromTills.filter(x => x['TillID'] == val)[0];
    this._accountsService.fnFetchTillCashAccountsBalance(till).subscribe(data => {
      this.tillCashAccounts = data.Data;
    });
  }

  onSelectFromTill(val) {
    this.toTills = [];
    this.getTillsCashAccounts(val);
    this.fromTills.forEach(element => {
      if (element['TillID'] != val) {
        this.toTills.push(element);
      }
    });

  }

  Form: UntypedFormGroup;
  formInit() {
    this.Form = this._fb.group({
      FromTill: new UntypedFormControl('', [Validators.required]),
      ToTill: new UntypedFormControl('', [Validators.required]),
    });
  }
  get f() {
    return this.Form.controls;
  }

  get ToTill() { return this.Form.get('ToTill'); }
  get FromTill() { return this.Form.get('FromTill'); }

  CurrencyDD: any[] = [];
  AccountsDD: any[] = [];

  onDecimalNumberFieldKeyPress($evt) {
    return DecimalValidation.isDecimalNumberKey($evt);
  }


  onNoClick(): void {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, {
      data: {
        message: null,
        buttonText: {
          ok: 'Ignore and Continue',
          cancel: 'Back to Form'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this._dialogRef.close("");
      }
    });
  }

  onSubmit() {

    if (!GetUserObject.GetBranchCode()) {



      const dialogRef = this._dialog.open(ConfirmationDialogComponent, {
        data: {
          message: 'You are not signed into any Branch',
          buttonText: {
            ok: 'Sign into Branch',
            cancel: 'Cancel'
          }
        }
      });

      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this._dialog.open(ChangeBranchDialogComponent, {
            data: {
              isForCurrencyRateBoard: false
              //pickNonBranch: self._route.snapshot.data.Data['Pick Non Branch']
            },
            width: '600px'
          }).afterClosed().subscribe(result => {
            if (result) {
              GetUserObject.SetSession(result);

            }
            else {

            }
          });
        }
        else {
          return;
        }
      });

      return;

    }



    this.submitted = true;
    if (this.Form.invalid) {
      return;
    }
    if (this.tillCashAccounts.filter(x => x.Amount > 0 && x.Amount > x.Balance).length > 0) {
      return;
    }

    if (this.tillCashAccounts.filter(x => x.Amount > 0).length == 0) {
      this._notificationService.error("Amount cannot be empty");
      return;
    }

    let obj = {
      FromTillID: this.Form.value.FromTill,
      ToTillID: this.Form.value.ToTill,
      Rows: this.tillCashAccounts.filter(x => x.Amount != null && x.Amount != 0),
      OwnerID: GetUserObject.GetOwnerID(),
      UserID: GetUserObject.GetUserID(),
      CreatedBy: GetUserObject.GetUserID(),
      SessionID: this.sessionId
    };

    this._accountsService.fnTransferCashBetweenTills(obj).subscribe(data => {
      if (data.Success)
        this._dialogRef.close();
    });
  }

  onNumberFieldKeyPress($evt) {
    return DecimalValidation.isNumberKey($evt);
  }

  onSelectFullAmount(val: CashTransferBetweenTillsModel) {
    if (val.isSelectedFullAmount)
      val.Amount = val.Balance;
    else
      val.Amount = 0;
  }

  onSelectFullAmountForAllCurrencies(evt) {
    if (evt.checked)
      this.tillCashAccounts.forEach(x => {
        x.Amount = x.Balance;
      });
    else
      this.tillCashAccounts.forEach(x => {
        x.Amount = (x.Amount == x.Balance) ? 0 : x.Amount;
      });
  }

  AddDenomination(val) {
    const dialogRef = this._dialog.open(DenominationCalculatorComponent,
      {
        data: {
          CalculatorData: { OwnerID: GetUserObject.GetOwnerID(), Currency: val.Currency, In: val.Amount || 0 },
          isCashTransfer: true
        }
      }
    );
    dialogRef.afterClosed().subscribe(result => {
      if (result != null || result != undefined) {
        val['DenominationsInOutList'] = result.DenominationsInOutList.filter(x => x.Quantity);
        if (result.TotalValue != 0 && val.Amount != result.Amount) {
          val.Amount = (NullNumberToNumber.toNumber(result.TotalValue));
        }
      }
    });
  }
}



