import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NumberFormatPipe } from 'src/app/shared/_pipes/number-format.pipe';

@Injectable({
    providedIn: 'root'
})
export class RegisterCommentService {
    constructor (private translate: TranslateService,
        private _numberFormatPipe: NumberFormatPipe,
    ) { }

    async getLocalisedComments(data: Array<any>) {

        for (let index = 0; index < data.length; index++) {
            const element = data[index];
            if (element.PreComment) {
                let amount = element.In > 0 ? element.In : element.Out;
                await this.translate.get([element.PreComment, , 'Exchange Rate'], { currency: element.CurrencyName, fromCurrency: element.Currency, toCurrency: element.SwapCurrency }).subscribe(async keys => {

                    const key = keys[element.PreComment];
                    element['PreCommentLocale'] = key;
                    // data[index]['comment'] = key;
                    if (element.isReceived) {
                        if (element.isSwap) {
                            data[index]['comment'] = key + ": " + this._numberFormatPipe.transform(amount) + " " + element.Currency + " " + keys['Exchange Rate'] + " : " + element.SwapTradingRate + " (" + element.Currency + "/" + element.SwapCurrency + ")";
                        }
                        else if (element.isCash && !element.isProduct) {
                            data[index]['comment'] = key + ": " + this._numberFormatPipe.transform(amount) + " " + element.Currency;
                        }
                        else if (element.isCheque) {
                            this.translate.get(['Amount', 'Cheque Type', 'Cheque Number', 'To the order of']).subscribe(keys => {
                                data[index]['comment'] = key + "\n"
                                    + keys['Amount'] + " : " + this._numberFormatPipe.transform(amount) + " " + element.Currency +
                                    "\n"
                                    + keys['Cheque Type'] + " : " + (element.ChequeFormData.TypeOfCheque == null ? " - " : element.ChequeFormData.TypeOfCheque) + "\n" + keys['Cheque Number'] + " : " +
                                    (element.ChequeFormData.ChequeNumber == null ? " - " : element.ChequeFormData.ChequeNumber) + "\n" +
                                    keys['To the order of'] + " : " + element.ChequeFormData.PayableTo;;
                            });
                        }
                        else if (element.isAccount) {
                            data[index]['comment'] = key + ": " + element.AccountName + ": " + this._numberFormatPipe.transform(amount) + " " + element.Currency;
                        }
                        else if (element.isTransfer) {
                            data[index]['comment'] = key + ": " + element.AccountName + ": " + this._numberFormatPipe.transform(amount) + " " + element.Currency;
                        }
                        else if (element.isEWire) {
                            data[index]['comment'] = key + ": " + element.AccountName + ": " + this._numberFormatPipe.transform(amount) + " " + element.Currency;
                        }
                        else if (element.isProduct) {
                            const product = element.ProductInfo;
                            this.translate
                                .get([
                                    "Product Bought",
                                    "Product",
                                    "Unit Price",
                                    "Fees",
                                    "Commission",
                                    "Tax",
                                    "Total",
                                ])
                                .subscribe((keys) => {
                                    let taxesInfo = product.InvoiceProductTaxes.map(
                                        (tax) =>
                                            `${ ' ' + tax.TaxCode }: ${ tax.TaxAmountFixed + tax.TaxAmountPercentage } ${ product.Currency }`
                                    ).join("\n");

                                    const comment =
                                        keys["Product Bought"] +
                                        " " +
                                        product.Quantity +
                                        " " +
                                        element.Currency +
                                        "\n" +
                                        keys["Product"] + ": " + product.ProductName +
                                        "\n" +
                                        keys["Unit Price"] +
                                        ": " +
                                        product.UnitPrice +
                                        " " +
                                        product.Currency +
                                        " per unit\n" +
                                        (product.FeeLocal ?
                                            (keys["Fees"] +
                                                ": " +
                                                product.FeeLocal +
                                                " " +
                                                product.Currency +
                                                "\n") : "")
                                        +
                                        (product.PercentFee ?
                                            (keys["Commission"] +
                                                ": " +
                                                product.PercentFee +
                                                "% = " +
                                                (product.TotalFee - product.FeeLocal) +
                                                " " +
                                                product.Currency +
                                                "\n") : "") +

                                        (taxesInfo ?
                                            (taxesInfo +
                                                "\n") : "")
                                        +
                                        keys["Total"] +
                                        ": " +
                                        product.TotalAmount +
                                        " " +
                                        product.Currency;

                                    data[index]['comment'] = comment;
                                });

                        }
                    }
                    else {
                        if (element.isSwap) {
                            data[index]['comment'] = key + ": " + this._numberFormatPipe.transform(amount) + " " + element.Currency + " " + keys['Exchange Rate'] + " : " + element.SwapTradingRate + " (" + element.Currency + "/" + element.SwapCurrency + ")";;
                        }
                        else if (element.isCash && !element.isProduct) {
                            if (element.isTransfer) {
                                data[index]['comment'] = key + ": " + element.AccountName + ": " + this._numberFormatPipe.transform(amount) + " " + element.Currency;
                            }
                            else {
                                data[index]['comment'] = key + ": " + this._numberFormatPipe.transform(amount) + " " + element.Currency;
                            }
                        }
                        else if (element.isCheque) {
                            this.translate.get(['Amount', 'Cheque Type', 'Cheque Number', 'To the order of']).subscribe(keys => {
                                data[index]['comment'] = key + "\n"
                                    + keys['Amount'] + " : " + this._numberFormatPipe.transform(amount) + " " + element.Currency +
                                    "\n"
                                    + keys['Cheque Type'] + " : " + (element.ChequeFormData.TypeOfCheque == null ? " - " : element.ChequeFormData.TypeOfCheque) + "\n" + keys['Cheque Number'] + " : " +
                                    (element.ChequeFormData.ChequeNumber == null ? " - " : element.ChequeFormData.ChequeNumber) + "\n" +
                                    keys['To the order of'] + " : " + element.ChequeFormData.PayableTo;;
                            });
                        }
                        else if (element.isAccount) {
                            data[index]['comment'] = key + ": " + element.AccountName + ": " + this._numberFormatPipe.transform(amount) + " " + element.Currency;
                        }
                        else if (element.isEWire) {
                            data[index]['comment'] = key + ": " + element.AccountName + ": " + this._numberFormatPipe.transform(amount) + " " + element.Currency;
                        }
                        else if (element.isWire) {
                            await this.translate.get(['Beneficiary Full Name', 'Beneficiary Address', 'Beneficiary Phone', 'Amount', 'Bank Name', 'Bank Address', 'SWIFT', 'Account No.']).subscribe(keys => {
                                data[index]['comment'] = key + "\n"
                                    + keys['Beneficiary Full Name'] + " : " + element.WireFormData?.BeneficiaryName +
                                    "\n"
                                    + keys['Beneficiary Address'] + " : " + (element?.BeneficiaryStreet ?? "") + " " + (element?.BeneficiaryCity ?? "") + " " + (element?.BeneficiaryStateProvince ?? "") + " " + (element?.BeneficiaryCountry ?? "") + " " + (element?.BeneficiaryZipCode ?? "") +
                                    "\n"
                                    + keys['Beneficiary Phone'] + " : " + element.WireFormData?.BeneficiaryPhone +
                                    "\n"
                                    + keys['Amount'] + " : " + this._numberFormatPipe.transform(amount) + " " + element.Currency +
                                    "\n"
                                    + keys['Bank Name'] + " : " + element.WireFormData?.BankName +
                                    "\n"
                                    + keys['Bank Address'] + " : " + (element?.BankingAddress ?? "") + " " + (element?.BankStreet ?? "") + " " + (element?.BankCity ?? "") + " " + (element?.BankStateProvince ?? "") + " " + (element?.BankCountry ?? "") + " " + (element?.BankZipCode ?? "") +
                                    "\n"
                                    + keys['SWIFT'] + " : " + element.WireFormData?.SwiftBICTransit +
                                    "\n"
                                    + keys['Account No.'] + " : " + element.WireFormData?.AccountNumber;
                            });
                        }
                        else if (element.isProduct) {
                            const product = element.ProductInfo;
                            this.translate
                                .get([
                                    "Product Sold",
                                    "Product",
                                    "Unit Price",
                                    "Fees",
                                    "Commission",
                                    "Discounts",
                                    "Tax",
                                    "Total",
                                ])
                                .subscribe((keys) => {
                                    let taxesInfo = product.InvoiceProductTaxes.map(
                                        (tax) =>
                                            `${ tax.TaxCode }: ${ (tax.TaxAmountFixed + tax.TaxAmountPercentage) } ${ element.BaseCurrency }`
                                    ).join("\n");

                                    const comment =
                                        keys["Product Sold"] +
                                        " " +
                                        product.Quantity +
                                        " " +
                                        element.Currency +
                                        "\n" +

                                        keys["Product"] + ": " + product.ProductName +
                                        "\n" +
                                        keys["Unit Price"] +
                                        ": " +
                                        product.UnitPrice +
                                        " " +
                                        element.BaseCurrency +
                                        " per unit\n" +
                                        (product.FeeLocal ?
                                            (keys["Fees"] +
                                                ": " +
                                                product.FeeLocal +
                                                " " +
                                                element.BaseCurrency +
                                                "\n") : "")
                                        +
                                        (product.PercentFee ?
                                            (keys["Commission"] +
                                                ": " +
                                                product.PercentFee +
                                                "% = " +
                                                (product.TotalFee - product.FeeLocal) +
                                                " " +
                                                element.BaseCurrency +
                                                "\n") : "") +
                                        (product.DiscountPercent ?
                                            (keys["Discounts"] +
                                                " " +
                                                product.DiscountPercent +
                                                "% : "
                                                +
                                                product.AmountBeforeTax * (parseFloat(product.DiscountPercent) / 100) +
                                                " " +
                                                element.BaseCurrency +
                                                "\n") : ""
                                        )
                                        +
                                        (taxesInfo ?
                                            (taxesInfo +
                                                "\n") : "")
                                        +
                                        keys["Total"] +
                                        ": " +
                                        product.TotalAmount +
                                        " " +
                                        element.BaseCurrency;

                                    data[index]['comment'] = comment;
                                });

                        }
                        else if (element.isTransfer) {
                            data[index]['comment'] = key + ": " + element.AccountName + ": " + this._numberFormatPipe.transform(amount) + "" + element.Currency;
                        }
                        else {
                            data[index]['comment'] = key + ": " + element.AccountName + ": " + this._numberFormatPipe.transform(amount);
                        }
                    }

                    if (data.length - 1 == index) {
                        return Promise.resolve(data);
                    }

                });
            }
        }
        return Promise.resolve(data);
    }

}
