import { CommonModule, DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { PasswordStrengthMeterModule } from 'angular-password-strength-meter';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ColorSketchModule } from 'ngx-color/sketch';
import { DenominationsComponent } from 'projects/cxr-crm/src/app/pages/admin/denominations/denominations.component';
import { SettleEwireDialogComponent } from 'projects/cxr-crm/src/app/pages/ewire/pending-ewires/settle-ewire-dialog/settle-ewire-dialog.component';
import { LinkInContactComponent } from 'projects/cxr-crm/src/app/pages/manage-contact/links/link-in-contact/link-in-contact.component';
import { AddEditAccountComponent } from 'projects/cxr-crm/src/app/pages/transactions/add-edit-account/add-edit-account.component';
import { translationUrl } from 'projects/cxr-crm/src/app/translationurl';
import { ToNumberPipe } from '../_helpers/ToNumberPipe';
import { WireTemplatesComponent } from '../shared/_generic/generic-components/_components/wire-templates/wire-templates.component';
import { GenericComponentsModule } from '../shared/_generic/generic-components/generic-components.module';
import { IbanValidationModule } from '../shared/_generic/iban-validation';
import { TriStateCheckboxComponent } from '../shared/_generic/tri-state-checkbox/tri-state-checkbox.component';
import { NumberFormatPipe } from '../shared/_pipes/number-format.pipe';
import { MaterialModule } from '../shared/material.module';
import { WireReceiveDialogComponent } from './Wire-Modals/wire-receive-dialog/wire-receive-dialog.component';
import { WireSellTemplateDialogComponent } from './Wire-Modals/wire-sell-template-dialog/wire-sell-template-dialog.component';
import { AddEditAccountDialogComponent } from './add-edit-account-dialog/add-edit-account-dialog.component';
import { AddEditLinkDialogComponent } from './add-edit-link-dialog/add-edit-link-dialog.component';
import { AddEditThirdPartyDialogComponent } from './add-edit-third-party-dialog/add-edit-third-party-dialog.component';
import { AddEditWireTemplateComponent } from './add-edit-wire-template/add-edit-wire-template.component';
import { AmlAlertDisplayDialogComponent } from './aml-alert-display-dialog/aml-alert-display-dialog.component';
import { AMLReviewDialogComponent } from './aml-review-dialog/aml-review-dialog.component';
import { AmountPaidToAccoutDialogComponent } from './amount-paid-to-accout-dialog/amount-paid-to-accout-dialog.component';
import { AmountReceivedToAccountDialogComponent } from './amount-received-to-account-dialog/amount-received-to-account-dialog.component';
import { AmountRecievedFromCustomerDialogComponent } from './amount-recieved-from-customer-dialog/amount-recieved-from-customer-dialog.component';
import { ChangePasswordDialogComponent } from './change-password-dialog/change-password-dialog.component';
import { ChequeModalComponent } from './cheque-modal/cheque-modal.component';
import { ChequePrintPreviewComponent } from './cheque-print-preview/cheque-print-preview.component';
import { ColorPickerDialogComponent } from './color-picker-dialog/color-picker-dialog.component';
import { CompanyKycReviewDialogComponent } from './company-kyc-review-dialog/company-kyc-review-dialog.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { CreateSessionDialogComponent } from './create-session-dialog/create-session-dialog.component';
import { CryptoDialogComponent } from './crypto-dialog/crypto-dialog.component';
import { DatabaseManagementComponent } from './database-management/database-management.component';
import { DateRangeDialogComponent } from './date-range-dialog/date-range-dialog.component';
import { DenominationCalculatorComponent } from './denomination-calculator/denomination-calculator.component';
import { DisplayAlertsDialogComponent } from './display-alerts-dialog/display-alerts-dialog.component';
import { EwireBuyTemplateDialogComponent } from './eWire-Modals/ewire-buy-template-dialog/ewire-buy-template-dialog.component';
import { EwireSellTemplateDialogTestComponent } from './eWire-Modals/ewire-sell-template-dialog-test/ewire-sell-template-dialog-test.component';
import { EwireSellTemplateDialogComponent } from './eWire-Modals/ewire-sell-template-dialog/ewire-sell-template-dialog.component';
import { GenericComponentDialogComponent } from './generic-component-dialog/generic-component-dialog.component';
import { IndividualKycReviewDialogComponent } from './individual-kyc-review-dialog/individual-kyc-review-dialog.component';
import { InvoiceCalculatorDialogComponent } from './invoice-calculator-dialog/invoice-calculator-dialog.component';
import { LoginAsOwnerDialogComponent } from './login-as-owner-dialog/login-as-owner-dialog.component';
import { LoginConfirmationDialogComponent } from './login-confirmation-dialog/login-confirmation-dialog.component';
import { NotePickerDialogComponent } from './note-picker-dialog/note-picker-dialog.component';
import { NotePreviewInvoiceComponent } from './note-preview-invoice/note-preview-invoice.component';
import { PicAccountDialogComponent } from './pic-account-dialog/pic-account-dialog.component';
import { PickAgentDialogComponent } from './pick-agent-dialog/pick-agent-dialog.component';
import { PickCryptoDialogComponent } from './pick-crypto-dialog/pick-crypto-dialog.component';
import { PickCurrencyBaseCurrencyDialogComponent } from './pick-currency-base-currency-dialog/pick-currency-base-currency-dialog.component';
import { PickCurrencyDialogComponent } from './pick-currency-dialog/pick-currency-dialog.component';
import { PickCustomerDialogComponent } from './pick-customer-dialog/pick-customer-dialog.component';
import { PickEwireForInvoiceComponent } from './pick-ewire-for-invoice/pick-ewire-for-invoice.component';
import { PickIndividualDialogComponent } from './pick-individual-dialog/pick-individual-dialog.component';
import { PickInoiceDialogComponent } from './pick-inoice-dialog/pick-inoice-dialog.component';
import { PickMultipleCustomersComponent } from './pick-multiple-customers/pick-multiple-customers.component';
import { PickOccupationDialogComponent } from './pick-occupation-dialog/pick-occupation-dialog.component';
import { PickThirdPartyComponent } from './pick-third-party/pick-third-party.component';
import { PickUserDialogComponent } from './pick-user-dialog/pick-user-dialog.component';
import { PickWireAccountsComponent } from './pick-wire-accounts/pick-wire-accounts.component';
import { PickWireTemplatesRevisedComponent } from './pick-wire-templates-revised/pick-wire-templates-revised.component';
import { PickWireTemplatesComponent } from './pick-wire-templates/pick-wire-templates.component';
import { PreviewGenericRowDataDialogComponent } from './preview-generic-row-data-dialog/preview-generic-row-data-dialog.component';
import { ProductInvoiceDialogComponent } from './product-invoice-dialog/product-invoice-dialog.component';
import { SelectProductDialogComponent } from './select-product-dialog/select-product-dialog.component';
import { SwapDialogNewComponent } from './swap-dialog-new/swap-dialog-new.component';
import { SwapTransactionDialogComponent } from './swap-transaction-dialog/swap-transaction-dialog.component';
import { TextConfirmationDialogComponent } from './text-confirmation-dialog/text-confirmation-dialog.component';
import { TransferBetweenAccountsComponent } from './transfer-between-accounts/transfer-between-accounts.component';
import { UserPinDialogComponent } from './user-pin-dialog/user-pin-dialog.component';
import { WalkinLimitExceedDialogComponent } from './walkin-limit-exceed-dialog/walkin-limit-exceed-dialog.component';
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, translationUrl, '');
}

@NgModule({
  declarations: [
    DisplayAlertsDialogComponent
    ,
    SwapTransactionDialogComponent,
    InvoiceCalculatorDialogComponent,
    PickCurrencyBaseCurrencyDialogComponent,
    PickCustomerDialogComponent,
    PickCurrencyDialogComponent,
    AmountRecievedFromCustomerDialogComponent,
    PreviewGenericRowDataDialogComponent,
    PickAgentDialogComponent,
    PickMultipleCustomersComponent,
    EwireSellTemplateDialogComponent,
    EwireBuyTemplateDialogComponent,
    LinkInContactComponent,
    AddEditAccountDialogComponent,
    AddEditAccountComponent,
    EwireSellTemplateDialogTestComponent,
    TriStateCheckboxComponent,
    PickEwireForInvoiceComponent,
    PickWireAccountsComponent,
    WireSellTemplateDialogComponent,
    PickWireTemplatesComponent,
    ChequeModalComponent,
    ChequePrintPreviewComponent,
    AddEditLinkDialogComponent,
    ChangePasswordDialogComponent,
    DenominationCalculatorComponent,
    TransferBetweenAccountsComponent,
    ConfirmationDialogComponent,
    GenericComponentDialogComponent,
    DenominationsComponent,
    AddEditThirdPartyDialogComponent,
    PickThirdPartyComponent,
    CompanyKycReviewDialogComponent,
    IndividualKycReviewDialogComponent,
    AddEditWireTemplateComponent,
    WireTemplatesComponent,
    SettleEwireDialogComponent,
    PickWireTemplatesRevisedComponent,
    NotePreviewInvoiceComponent,
    AMLReviewDialogComponent,
    PickInoiceDialogComponent,
    LoginAsOwnerDialogComponent,
    LoginConfirmationDialogComponent,
    PicAccountDialogComponent,
    TextConfirmationDialogComponent,
    ToNumberPipe,
    AmlAlertDisplayDialogComponent,
    DateRangeDialogComponent,
    ColorPickerDialogComponent,
    PickIndividualDialogComponent,
    NotePickerDialogComponent,
    PickOccupationDialogComponent,
    DatabaseManagementComponent,
    AmountReceivedToAccountDialogComponent,
    DisplayAlertsDialogComponent,
    WalkinLimitExceedDialogComponent,
    WireReceiveDialogComponent, PickUserDialogComponent, AmountPaidToAccoutDialogComponent, UserPinDialogComponent, ProductInvoiceDialogComponent, SelectProductDialogComponent, CryptoDialogComponent, PickCryptoDialogComponent, SwapDialogNewComponent,
    CreateSessionDialogComponent
  ],
  exports: [
    TextConfirmationDialogComponent,
    TriStateCheckboxComponent,
    InvoiceCalculatorDialogComponent,
    PickCurrencyBaseCurrencyDialogComponent,
    PickCustomerDialogComponent,
    PickCurrencyDialogComponent,
    AmountRecievedFromCustomerDialogComponent,
    PreviewGenericRowDataDialogComponent,
    PickAgentDialogComponent,
    PickMultipleCustomersComponent,
    EwireSellTemplateDialogComponent,
    EwireBuyTemplateDialogComponent,
    LinkInContactComponent,
    GenericComponentsModule,
    AddEditAccountDialogComponent,
    EwireSellTemplateDialogTestComponent,
    PickEwireForInvoiceComponent,
    PickWireAccountsComponent,
    WireSellTemplateDialogComponent,
    PickWireTemplatesComponent,
    ChequeModalComponent,
    ChequePrintPreviewComponent,
    AddEditLinkDialogComponent,
    ChangePasswordDialogComponent,
    DenominationCalculatorComponent,
    TransferBetweenAccountsComponent,
    ConfirmationDialogComponent,
    DenominationsComponent,
    AddEditThirdPartyDialogComponent,
    CompanyKycReviewDialogComponent,
    IndividualKycReviewDialogComponent,
    AddEditWireTemplateComponent,
    WireTemplatesComponent,
    SettleEwireDialogComponent,
    PickWireTemplatesRevisedComponent,
    NotePreviewInvoiceComponent,
    AMLReviewDialogComponent,
    SwapTransactionDialogComponent,
    ColorPickerDialogComponent,
    DateRangeDialogComponent,
    DisplayAlertsDialogComponent, PickUserDialogComponent, UserPinDialogComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    GenericComponentsModule,
    BsDatepickerModule.forRoot(),
    PasswordStrengthMeterModule,
    IbanValidationModule,
    MatSliderModule,
    MatSlideToggleModule, MatIconModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      extend: true
    }),
    ColorSketchModule
  ],
  providers: [
    ToNumberPipe,
    DatePipe, NumberFormatPipe
  ]
})
export class SharedDialogModule { }
