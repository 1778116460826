import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { WhiteSpaceValidator } from 'src/app/_helpers';
import { GetUserObject } from '../../../../projects/cxr-crm/src/app/pages/_helpers/get-user-object';
import { UserSessionService } from '../../../../projects/cxr-crm/src/app/pages/_services/user-session.service';

@Component({
  selector: 'cxr-create-session-dialog',
  templateUrl: './create-session-dialog.component.html',
  styleUrls: ['./create-session-dialog.component.scss']
})
export class CreateSessionDialogComponent implements OnInit {
  AddUserSessionForm: FormGroup;
  submitted = false;
  currentDate = GetUserObject.GetCurrentDateTime();
  constructor (private fb: FormBuilder, private _dialogRef: MatDialogRef<CreateSessionDialogComponent>,
    private _userSessionService: UserSessionService
  ) {
  }

  ngOnInit(): void {
    this.AddUserSessionForm = this.fb.group({
      ID: [null],
      Pin: ['', [Validators.required, WhiteSpaceValidator.cannotContainSpace]],
      StartDate: [this.currentDate, Validators.required],
    });
  }

  get f() {
    return this.AddUserSessionForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.AddUserSessionForm.invalid) {
      return;
    }
    this._userSessionService.fnCreateSession(this.AddUserSessionForm.value).subscribe(data => {
      if (data.Success) {
        this._dialogRef.close(data.Data);
      }
    });
  }
  close() {
    this._dialogRef.close();
  }
}
