import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {



  constructor
    (
      private readonly snackBar: MatSnackBar,
      private readonly zone: NgZone,
      private translate: TranslateService,
    ) {

    // translate.addLangs(Localisation.supportLanguages);
    // const browserLang = GetUserObject.getLanguage();
    // translate.use(browserLang.match(Localisation.matchLanguageExpression) ? browserLang : Localisation.defaultLanguage);

  }

  default(message: string, isHandset?: boolean) {
    this.show(
      message,
      {
        panelClass: 'default-notification-overlay',
      },
      isHandset
    );
  }

  info(message: string, isHandset?: boolean) {
    this.show(
      message,
      {
        panelClass: 'info-notification-overlay',
      },
      isHandset
    );
  }

  success(message: string, isHandset?: boolean) {
    this.show(
      message,
      {
        panelClass: 'success-notification-overlay',
      },
      isHandset
    );
  }

  warn(message: string, isHandset?: boolean) {
    this.show(
      message,
      {
        panelClass: 'warning-notification-overlay',
      },
      isHandset
    );
  }

  error(message: string, isHandset?: boolean) {
    this.show(
      message,
      {
        panelClass: 'error-notification-overlay',
      },
      isHandset
    );
  }

  private show(
    message: string,
    configuration: MatSnackBarConfig,
    isHandset?: boolean
  ) {

    this.translate.get(message).subscribe(res => {
      // If desktop, move it to top-right
      if (!isHandset) {
        configuration.horizontalPosition = 'right';
        configuration.verticalPosition = 'top';
        configuration.duration = 3000;
      }

      // Need to open snackBar from Angular zone to prevent issues with its position per
      // https://stackoverflow.com/questions/50101912/snackbar-position-wrong-when-use-errorhandler-in-angular-5-and-material
      this.zone.run(() => this.snackBar.open(res, null, configuration));
    });

  }

  public dismiss(): void {
    this.snackBar.dismiss();
  }
}
