import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CreateSessionDialogComponent } from 'src/app/shared-dialog/create-session-dialog/create-session-dialog.component';
import { NotificationService } from 'src/app/shared/_services';
import { UserSessionService } from './user-session.service';

@Injectable({
    providedIn: 'root'
})
export class UserSessionHandleService {


    constructor (private _userSessionService: UserSessionService, private _notificationService: NotificationService,
        private _dialog: MatDialog, private _location: Location
    ) { }

    async handleSession(isDialog: boolean = false): Promise<number> {
        try {
            // Convert the Observable to a Promise and wait for the result
            const data = await this._userSessionService.fnGetUserActiveSession().toPromise();

            if (data.Data > 0) {
                // If there is an active session, resolve the promise with the session data
                return data.Data;
            } else {
                // No active session: Show a notification and open a dialog
                this._notificationService.error("No active session available to create an invoice");

                const dialogRef = this._dialog.open(CreateSessionDialogComponent, {
                    data: true,
                    width: '600px',
                });

                // Wait for the dialog to close and return the result
                const dialogData = await dialogRef.afterClosed().toPromise();
                console.log(dialogData);

                if (dialogData) {
                    return dialogData;
                } else {
                    if (isDialog)
                        this._dialog.closeAll();
                    else
                        this._location.back();
                    return null;
                }
            }
        } catch (error) {
            console.error("Error handling session:", error);
            throw error; // Propagate the error for further handling if needed
        }
    }


}
